.category-display-main {
    padding: 40px 0 40px 0;
    background-color: #e7e6e6;
}

.category-display-main h1 {
    text-align: center;
    margin-bottom: 20px;
    color: rgb(59, 59, 59);
}

.category-display {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.category-link {
    text-decoration: none; /* Removes underline from links */
    width: 30%;
}

.category-item {
    font-family: 'Poppins';
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    color: white;
    font-size: 24px;
    text-shadow: 1px 1px 8px rgba(0,0,0,0.7);
    transition: transform 0.3s ease; /* Smooth transition for transform changes */
}
.category-item h2{
    text-align: center;
}
.category-item:hover {
    transform: scale(1.03); /* Slightly increase size on hover */
}

/* Responsive adjustments for different screen sizes */

/* Large Tablets and small desktops */
@media (max-width: 1024px) {
    .category-display {
        grid-template-columns: repeat(2, 1fr);
    }
    .category-item {
        font-size: 22px; /* Slightly smaller text */
        height: 250px; /* Slightly smaller height */
    }
    .category-link {
        text-decoration: none; /* Removes underline from links */
        width: 45%;
    }
}

/* Tablets */
@media (max-width: 800px) {
    .category-item {
        height: 200px; /* Reduce height further for tablet view */
        font-size: 20px; /* Reduce font size for clarity */
        margin-bottom: 10px; /* Add space between items */
    }
}

/* Large Phones */
@media (max-width: 600px) {
    .category-display {
        grid-template-columns: repeat(1, 1fr);
    }
    .category-item {
        font-size: 18px; /* Even smaller font for smaller screens */
        height: 200px; /* Adjust height for large phones */
    }
    .category-link {
        text-decoration: none; /* Removes underline from links */
        width: 90%;
    }
}

/* Small Phones */
@media (max-width: 400px) {
    .category-display {
        gap: 10px; /* Reduce gap */
    }
    .category-item {
        font-size: 16px; /* Smallest font size for readability on small devices */
        height: 150px; /* Reduce height to fit small screens */
    }
}
