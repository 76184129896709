/* General Styles */
.navbar {
  width: 100%;
}

/* Announcement banner */
.announcement {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  background: #ffcc00;
  padding: 10px 0;
}

.announcement p {
  display: inline-block;
  padding-left: 30%;
  animation: slide 25s linear infinite;
  font-size: 16px;
  margin: 0;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Upper Navbar */
.upper-nav {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background-color: #6aa84f;
  color: #ffffff;
  height: fit-content;
}

.upper-nav-bar-link {
  text-decoration: none;
  color: #ffffff;
  display: flex;
}

.upper-left-nav {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0 0 0 6%;
}

.upper-left-nav img {
  width: 25px;
  height: 25px;
}

.upper-left-nav span {
  padding-left: 10px;
}

.upper-left-nav a {
  text-decoration: none;
  color: white;
  font-weight: 500;
}

.upper-right-nav {
  display: flex;
  align-items: center;
  margin: 0 6% 0 0;
}

.upper-right-nav img {
  width: 25px;
  height: 25px;
}

.upper-right-nav p {
  padding-left: 10px;
}

.medium-bold {
  font-weight: 500;
}

/* Middle Navbar */
.middle-nav {
  display: flex;
  justify-content: space-between;
  padding: 0px 6% 0px 6%;
  background-color: #696969;
  align-items: center;
}
.middle-nav-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.nav-menu {
  display: flex;
  list-style: none;
  gap: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}
.menu-items {
  display: flex;
}
.nav-logo img {
  max-height: 100px;
}

.nav-menu li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0 10px;
  cursor: pointer;
  flex-grow: 1;
  white-space: nowrap;
}

.nav-menu a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.nav-menu hr {
  color: #ffffff;
  width: 90%;
  margin: 0px;
}

.nav-menu hr:hover {
  color: #696969;
}

/* Mobile Room Categories */
.mobile-room-categories {
  display: none; /* Hidden by default - will be shown in mobile mode */
}

.nav-login-cart {
  display: flex;
  align-items: center;
  gap: 45px;
}

.nav-login-cart button {
  width: 120px;
  height: 40px;
  outline: none;
  border: none;
  border-radius: 75px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  background-color: #5dcb53;
  transition: background-color 0.3s ease-in-out;
}

.nav-login-cart button:active {
  background-color: #408d39;
}

.nav-login-cart img {
  width: 40px;
  height: 40px;
}

.nav-cart-count {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -35px;
  margin-left: -55px;
  border-radius: 11px;
  font-size: 11px;
  background-color: red;
  color: white;
}

.nav-bar-link {
  text-decoration: none;
  color: #ffffff;
  transition: color 0.3s ease-in-out;
}

.nav-bar-link:hover {
  text-decoration: none;
  color: #5dcb53;
}

.nav-dropdown {
  display: none;
}

/* Lower Navbar */
.lower-nav {
  background-color: #ffffff;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: fit-content;
  flex-wrap: wrap;
}

.room-nav-menu {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.room-nav-menu li {
  margin: 0 1px;
  position: relative;
}

.room-nav-menu a {
  text-decoration: none;
  color: #ffffff;
  background-color: #6aa84f;
  padding: 5px 10px;
  transition: background-color 0.3s ease-in-out;
  border-radius: 5px;
  text-wrap: nowrap;
}

.room-nav-menu a:hover {
  background-color: #4e7b37;
}

.product-type-filter {
  display: none;
  border-radius: 999px;
  border: solid 2px rgb(99 227 87);
  width: fit-content;
}

.lower-nav .product-type-filter {
  display: flex;
}
.product-type-filter-item {
  padding: 5px 20px;
  border: none;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.5s ease, color 0.5s ease; 
}
/* Responsive Styles */
@media (max-width: 1280px) {
  .nav-menu {
    gap: 20px;
    font-size: 18px;
  }
  .nav-login-cart {
    gap: 40px;
  }
  .nav-login-cart button {
    width: 100px;
  }
  .nav-bar-link img {
    max-width: 160px;
  }
}

@media (max-width: 1024px) {
  .nav-menu {
    gap: 18px;
    font-size: 16px;
  }
  .nav-menu li {
    padding: 0;
  }
  .nav-login-cart {
    gap: 40px;
  }
  .room-nav-menu {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    flex-wrap: wrap;
    padding: 0;
    width: 500px;
    row-gap: 20px;
  }
  .nav-login-cart button {
    width: 100px;
    height: 35px;
    border-radius: 65px;
  }
  .nav-bar-link img {
    max-width: 120px;
  }
  .nav-login-cart img {
    width: 35px;
    height: 35px;
  }
  .upper-left-nav img {
    width: 20px;
    height: 20px;
  }
  .upper-left-nav p {
    font-size: 14px;
  }
  .upper-right-nav img {
    width: 20px;
    height: 20px;
  }
  .upper-right-nav p {
    font-size: 14px;
  }
}

@media (max-width: 800px) {
  .nav-menu {
    display: none;
    gap: 15px;
    font-size: 14px;
    position: absolute;
    border-radius: 0 0 5px 5px;
    top: 135px;
    margin: auto;
    background-color: #696969;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .menu-items {
    display: flex;
    flex-direction: column;
    font-size: 25px;
    gap: 5px;
  }
  .nav-menu-visible {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(105, 105, 105, 0.95);
    z-index: 9999;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0;
    list-style: none;
    font-size: 25px;
    padding:20px 0 20px 0
  }
  .mobile-room-categories {
    display: block; /* Show room categories in mobile mode */
  }
  .lower-nav {
    display: none; /* Hide lower nav in mobile mode */
  }
  .product-type-filter {
    display: flex;
    margin: auto;
    margin-top: 20px;
  }
  .nav-login-cart {
    gap: 40px;
  }
  .nav-login-cart button {
    width: 70px;
    height: 25px;
    border-radius: 65px;
    font-size: 15px;
  }
  .nav-bar-link img {
    max-width: 100px;
  }
  .nav-login-cart img {
    width: 25px;
    height: 25px;
  }
  .upper-left-nav img {
    width: 15px;
    height: 15px;
  }
  .upper-left-nav {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: start;
  }
  .upper-left-nav p {
    font-size: 12px;
  }
  .upper-right-nav img {
    width: 15px;
    height: 15px;
  }
  .upper-right-nav p {
    font-size: 12px;
  }
  .nav-cart-count {
    width: 15px;
    height: 15px;
    margin-left: -50px;
    font-size: 10px;
  }
  .nav-dropdown {
    display: block;
    width: 20px;
    height: 30px;
    position: relative;
    z-index: 10000;
    cursor: pointer;
  }
  .product-type-filter-item {
    padding: 5px 10px;
    border: none;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.5s ease, color 0.5s ease; 
  }
}

@media (max-width: 400px) {
  .middle-nav {
    padding: 12px 6% 8px 6%;
    margin: 0;
  }
  .nav-menu {
    display: none;
    gap: 15px;
    font-size: 14px;
    position: absolute;
    top: 115px;
    border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
  }
  .menu-items {
    display: flex;
    flex-direction: column;
    font-size: 25px;
    gap: 5px;
  }
  .nav-menu li {
    flex-grow: 0;
  }
  .nav-login-cart {
    gap: 40px;
  }
  .nav-login-cart button {
    width: 70px;
    height: 25px;
    border-radius: 65px;
    font-size: 15px;
  }
  .nav-bar-link img {
    max-width: 80px;
  }
  .nav-login-cart img {
    width: 25px;
    height: 25px;
  }
  .upper-left-nav img {
    width: 15px;
    height: 15px;
  }
  .upper-left-nav p {
    font-size: 12px;
  }
  .upper-left-nav a {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 10px;
  }
  .upper-right-nav img {
    width: 15px;
    height: 15px;
  }
  .upper-right-nav p {
    font-size: 12px;
  }
  .nav-cart-count {
    width: 15px;
    height: 15px;
    margin-left: -50px;
    font-size: 10px;
  }

  .nav-dropdown {
    display: block;
  }
  .nav-menu-visible {
    display: flex;
    font-size: 25px;
  }
  .announcement {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    background: #ffcc00;
    padding: 0px 0;
  }
  .announcement p {
    font-size: 10px;
  }
  .product-type-filter-item {
    padding: 5px 5px;
    border: none;
    font-size: 15px;
    cursor: pointer;
    transition: background-color 0.5s ease, color 0.5s ease; 
  }
}
