.format-item{
    width: 100%;
    overflow: scroll;
}
.formatMain, .format {
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 75px;
    padding: 20px 75px;
    width: 100%;
    min-width: 600px;
}

.format img {
    cursor: pointer; /* Indicates that the images are clickable */
}

.format img.cartIcon-productIcon {
    width: 70px; /* Adjust size as needed */
    height: auto;
}

.remove-icon {
    width: 20px;
    height: auto;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.remove-icon:hover {
    transform: scale(1.1);
}

.cartitems-down {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.cartitems-total {
    display: flex;
    justify-content: space-between;
}

.cartitems-total h2 {
    margin-right: 40px;
    color: rgb(59, 59, 59);
}

.checkout-button-container {
    display: flex;
    gap: 10px;
}

.checkout-button-container button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #6AA84F; /* Example color */
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 20px;
}

.checkout-button-container button:hover {
    background-color: #5C9A4F;
}

.checkout-button-container button.active {
    background-color: #4F8B3C; /* Active button color */
}

/* Modal styles */
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 100%;
}
.modal-content-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}
.modal-content-header img{
    width: 15px;
    height: 15px;
    cursor: pointer;
}
.modal-content-header img:hover{
    width: 17px;
    height: 17px;
    cursor: pointer;
}
.modal-content-buttons{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    row-gap: 10px;
}
.modal-content input, .modal-content select {
    display: block;
    padding: 10px;
    margin-bottom: 10px;
    width: 85%;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.modal-content button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #6AA84F; /* Example color */
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 20px;
}

.modal-content button:hover {
    background-color: #5C9A4F;
}

.excludes-text {
    margin-top: 20px;
    font-size: 12px;
    color: #666;
}
@media (max-width: 1280px) {
    .formatMain, .format {
        display: grid;
        grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr 1fr;
        align-items: center;
        gap: 20px;
        padding: 10px 30px;
    }
}
@media (max-width: 768px) {
    .cartItems {
        margin: 30px 20px;
    }

    .formatMain, .format {
        grid-template-columns: 0.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
        gap: 10px;
        padding: 20px 10px;
    }

    .cartitems-down {
        flex-direction: column;
        gap: 20px;
        padding: 20px 10px;
    }

    .cartitems-total {
        flex-direction: column;
        align-items: flex-start;
    }

    .cartitems-total h2 {
        margin-bottom: 5px;
    }

    .checkout-button-container {
        flex-direction: column;
    }

    .checkout-button-container button {
        width: 90%;
    }
}
@media (max-width: 500px) {
    .modal-content {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        text-align: center;
        max-width: 400px;
        width: 80%;
    }
}
@media (max-width: 400px) {
    .formatMain, .format {
        grid-template-columns: 0.5fr 1.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
        gap: 5px;
        padding: 10px 5px;
    }
    .cartitems-total h2 {
        font-size: 14px;
    }

    .checkout-button-container button {
        font-size: 14px;
        width: 100%;
    }

    .modal-content input, .modal-content select {
        width: 100%;
    }

    .modal-content {
        width: 80%;
    }
}

