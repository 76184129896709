.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    background-color: #696969;
    color: white;
}

.footer-logo {
    margin-top: 30px;
    width: 40%;
    max-width: 200px;
}
.footer-logo-link{
    display: block;
    width: 100%;
}
.footer-logo-link img{
    width: 100% ;
}

.footer-links{
    display: flex;
    list-style-type: none;
    gap: 50px;
    font-size: 20px;
}

.footer-links li {
    cursor: pointer;

}
.footer-link-direct{
    cursor: pointer;
    text-decoration: none;
    color: white;
}

.footer-link-direct:hover{
    
    color: #5DCB53;
}

.footer-social-icon {
    display: flex;
    gap: 20px;
    color: white;
}

.footer-icon-container {
    padding: 10px 10px 6px 10px;
    color: white;
}

.footer-icon-container img{
    width: 50px;
    height: 50px;
    fill: white;
    cursor: pointer;
}




.footer-copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    margin-bottom: 30px;

}

.footer-copyright hr {
    width: 80%;
    border: none;
    border-radius: 10px;
    height: 3px;
    background: #c7c7c7;    
}
.footer-copyright p {
    width: 100%;
    text-wrap: balance;
    text-align: center;
}
/* Media Queries */
@media (max-width: 1280px) {
    /* Adjust footer links gap and font size */
    .footer-links {
        gap: 30px;
        font-size: 18px;
    }
}

@media (max-width: 1024px) {
    /* Stack social icons vertically for narrower screens */
    .footer-social-icon {
        align-items: center;
    }
}

@media (max-width: 800px) {
    /* Reduce the gap in the footer */
    .footer {
        gap: 30px;
    }
    /* Adjust the size of icons */
    .footer-icon-container img {
        width: 40px;
        height: 40px;
    }
    .footer-links {
        gap: 20px;
    }
}

@media (max-width: 500px) {
    /* Further reduce the font size of footer links */
    .footer-links {
        font-size: 16px;
    }
    /* Reduce gap for social icons */
    .footer-social-icon {
        gap: 10px;
    }
    .footer-icon-container img {
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 400px) {
    /* Adjust layout for very small screens */
    .footer-links {
        align-items: center;
        flex-direction: column;
        gap: 4px;
    }
    .footer-links li {
        /* Improve tap target size for touch devices */
        padding: 10px;
    }
}
