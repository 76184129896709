.bannerImg{
    width: 100%;
    height: auto; /* Adjust for aspect ratio */
}

.stores h1{
    text-align: center;
    padding: 100px 0px;
    color: white;
    font-size: 50px;
}

.stores p {
    margin-bottom: 20px;
}

.stores-container {
    align-items: center;
    margin: 20px 7% 20px 7%;
}

.store-one, .store-two {
    display: flex;
    justify-content: space-between;
    background-color: #408d39;
    padding: 0 0 0 20px;
    border-radius: 15px;
    color: white;
    margin: 40px 0;
}

.store-one iframe, .store-two iframe {
    width: 700px;
    height: 400px;
    border: none;
    border-radius: 0 10px 10px 0;
}

.storeDetails h2 {
    font-size: 40px;
    margin: 15px 0 20px 0px;
}

.storeDetails p {
    font-size: 20px;
}

.storeDetails p span {
    color: rgb(208, 208, 208);
}

.titles{
    font-weight: 600;
    line-height: 0;
    font-size: 20px;
}

.store-social-icon {
    display: flex;
    gap: 20px;
}

.store-icon-container {
    padding: 0px 10px 6px 10px;
}

.store-icon-container img{
    width: 40px;
    height: 40px;
    cursor: pointer;
}

/* Media Queries */
@media (max-width: 1024px) {
    /* Adjust iframe size for tablets and below */
    .store-one iframe, .store-two iframe {
        width: 400px; /* Slightly smaller to fit tablet screens */
        height: 380px; /* Adjust height to maintain aspect ratio */
    }
    .stores h1 {
        font-size: 40px;
        padding-top: 50px;
    }
    .storeDetails h2 {
        font-size: 35px;
    }
    .storeDetails p {
        font-size: 18px;
    }
}

@media (max-width: 800px) {
    .store-one, .store-two {
        flex-direction: column;
    }
    .store-one iframe, .store-two iframe {
        width: 100%; /* Full width of the container */
        height: 400px; /* Auto-adjust height to maintain aspect ratio */
    }
    .stores-container {
        margin: 20px 5% 20px 5%;
    }
    .storeDetails h2, .storeDetails p {
        text-align: center;
    }
    .store-social-icon {
        margin-left: 30%;
    }
}

@media (max-width: 600px) {
    .stores h1 {
        font-size: 35px;
        padding-top: 30px;
    }
    .storeDetails h2 {
        font-size: 30px;
    }
    .storeDetails p {
        font-size: 16px;
    }
    .store-social-icon {
        justify-content: center;
    }
    .store-icon-container img {
        width: 35px;
        height: 35px;
    }
    .store-social-icon {
        margin-left: 2%;
    }
}

@media (max-width: 400px) {
    .stores-container {
        margin: 10px 3% 10px 3%;
    }
    .titles, .storeDetails p {
        font-size: 15px;
    }
}