.color{
    background-color: #e7e6e6;
}
.whyUs{
    margin: 0 6% 0 6%;
    padding: 20px 0 20px 0;
}

.title {
    text-align: center;
    margin-bottom: 25px;
    font-size: 30px;
    color: rgb(59, 59, 59);
}

.flexx{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.container{
    padding: 15px;
    margin: 15px;
    text-align: center;
}


.container img{
    width: 100px;
    margin-bottom: 10px;
}

/* Media Queries */
@media (max-width: 1280px) {
    .title {
        font-size: 28px; /* Slightly smaller font */
    }
    .container {
        padding: 10px;
        margin: 10px;
    }
}

@media (max-width: 1024px) {
    .flexx {
        flex-direction: column; /* Stack items vertically */
    }
    .container {
        padding: 8px;
        margin: 8px;
    }
    .container img {
        width: 90px; /* Smaller image */
    }
}

@media (max-width: 800px) {
    .whyUs {
        margin: 0 5% 0 5%; /* Adjust margins */
        padding: 15px 0 15px 0; /* Adjust padding */
    }
    .title {
        font-size: 26px; /* Reduce title font size */
    }
}

@media (max-width: 500px) {
    .title {
        font-size: 24px; /* Further reduce title font size */
    }
    .container img {
        width: 80px; /* Further reduce image size */
    }
}

@media (max-width: 400px) {
    .whyUs {
        margin: 0 4% 0 4%; /* Tighter margins */
    }
    .title, .container {
        font-size: 22px; /* Smaller text overall */
        padding: 5px; /* Less padding */
        margin: 5px; /* Less margin */
    }
    .flexx {
        flex-direction: column;
        justify-content: center; /* Center items when stacked */
    }
}