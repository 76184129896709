.room-category h2 {
    text-align: center;
    margin: 15px 0px;
}

.room-products {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr); /* Default for larger screens */
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

/* Responsive Design for smaller screens */
@media (max-width: 1024px) {
    .room-products {
        grid-template-columns: repeat(3, 1fr); /* 3 columns for medium screens */
    }
}

@media (max-width: 768px) {
    .room-products {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    }
}

@media (max-width: 480px) {
    .room-products {
        grid-template-columns: 1fr; /* 1 column for small screens */
    }
}
