.contact-us {
    background-color: #fff; /* White background */
    color: rgb(59, 59, 59); /* Dark grey text color */
    padding: 20px;
    font-family: 'Poppins', sans-serif; /* Match the font to your brand */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 6% 20px 6%;
}

.contact-us h1 {
    color: #5DCB53; /* Highlight color for heading */
    margin-bottom: 1em;
}

.contact-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.left-content p {
    margin-bottom: 10px;
}

.left-content,
.right-content {
    width: 48%; /* Adjust the width as necessary */
}

.contact-form label {
    display: block;
    margin: 0.5em 0 0.2em;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
    width: 100%;
    padding: 0.5em;
    margin-bottom: 1em;
    border: 1px solid #ccc; /* Light grey border for inputs */
}

.contact-form button {
    background-color: #5DCB53; /* Highlight color for buttons */
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Poppins';
    border-radius: 20px;
}

.contact-form button:hover {
    background-color: #4cb848; /* Darker shade for hover effect */
}

.contact-info {
    margin-top: 1em;
}

.contact-info a {
    color: #5DCB53; /* Highlight color for links */
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}

.opening-times {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items at the start of the flex container */
    width: 100%; /* Ensure the container takes the full width */
    padding: 20px;
    margin: 20px 6% 20px 6%; /* Consistent spacing */
}

.shop-image img {
    width: 100%; /* Responsive width */
    max-width: 600px; /* Limit maximum width */
    border-radius: 30px;
}

.left-opening p{
    margin-bottom: 10px;
}



.learn-more {
    margin-top: 30px;
}

.learn-more span{
    font-weight: 600;
    font-size: 22px;
    
}

.opening-times h2 {
    color: rgb(59, 59, 59); /* Same as the text color */
    margin-bottom: 20px;
}

.status {
    color: #5DCB53; /* Highlight color */
    font-weight: bold;
    margin-left: 1em;
}

.learn-more-button {
    background-color: #5DCB53; /* Highlight color for buttons */
    color: #fff;
    padding: 10px 20px;
    font-family: 'Poppins';
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 20px;
    margin-top: 10px; /* Some spacing above the button */
  }
  
  .learn-more-button:hover {
    background-color: #4cb848; /* Darker shade for hover effect */
  }

@media (max-width: 768px) {
    .contact-content,
    .opening-times {
        flex-direction: column;
    }

    .left-content,
    .right-content,
    .left-opening,
    .shop-image {
        width: 100%; /* Stack content vertically on smaller screens */
        margin-bottom: 1em;
    }
}