.hero {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap; /* This will allow items to wrap onto the next line if they don't fit */
}

.hero-left {
    flex-basis: calc(50% - 12%); /* Adjust this value as needed, the subtraction accounts for the margin */
    margin: 6% 0 5% 6%;
    padding: 20px;
    box-sizing: border-box; /* Makes sure padding is included in width calculation */
}
.hero-left h1{
    color: #ffffff;
    font-size: 80px;
    padding: 0px;
    margin: 0px;
    line-height: 80px;
}

.hero-sale-link {
    text-decoration: none;
}

.c-red {
    color: rgb(255, 16, 16);
}

.hero-left p {
    color: #ffffff;
    line-height: 30px;
    font-size: 25px;
    padding-left: 3px;
}

.hero-left button {
    background-color: rgb(255, 16, 16);
    border: 0px rgb(255, 16, 16);
    border-radius: 25px;
    color: #ffffff;
    font-size: 30px;
    padding: 5px 20px 5px 20px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 10px;
}

.hero-left button:active {
    
    background-color: rgb(249, 63, 63);
}

.hero-right {
    flex-basis: calc(50% - 12%); /* Adjust this value as needed, the subtraction accounts for the margin */
    margin: 6% 6% 6% 0;
    box-sizing: border-box; /* Makes sure padding is included in width calculation */
    display: flex;
    justify-content: center; /* Centers the image within the div */
    align-items: center; /* Aligns vertically */
}

.countdown2 {
    font-size: 30px;
    font-weight: bold;
    align-items: center;
    margin-left: 30px;
    color: white;
    text-align: center;
}

.timer-flex {
    display: flex;
    align-items: center;
}

.timer-box2 {
    background-color: white; /* Keeping timer boxes white for contrast */
    color: black; /* Text color for readability */
    padding: 8px 10px;
    border-radius: 5px; /* Rounded corners for aesthetic */
    margin: 0 5px;
    font-size: 48px;
    display: flex;
    flex-direction: column; /* Stacks children vertically */
    align-items: center; /* Centers items horizontally within the div */
    justify-content: center; /* Centers items vertically within the div */
    line-height: 1; /* Reduces the line height to minimize the gap */
}

.timer-label-inline {
    font-size: 20px; /* Adjust as necessary */
    margin-top: 0; /* No margin */
    display: block;
    text-align: center;
    line-height: 1; /* Reduces the line height to minimize the gap */
    padding: 0; /* No padding */
}

.lower-hero{
    padding: 20px 6% 20px 6%;
    background-color: #e7e6e6;
}
.features {
    display: flex;
    justify-content: space-between;
    
}

.features li {
    text-decoration: none;
    display: flex;
    flex-direction:row;
}

.features h2 {
    font-size: 20px;
    color: rgb(59, 59, 59);
}

.features img {
    max-width: 30px;
    margin-right: 15px;
    
}

@media(max-width:1280px){
    .hero-left, .hero-right {
         /* On smaller screens, they take full width */
        margin: 6% 6% 6% 0%; /* Updated to add margin on the right for .hero-left */
    }

    .hero-right img {
        max-width: 300px; /* Adjust this value if the image is still too large */
    }
    .hero-left {
        margin: 6% 0 0% 6%;
        padding: 20px;
        
    }
    .hero-left h1{
        font-size: 50px;
        line-height: 55px;
    }
    .hero-left p {
        line-height: 30px;
        font-size: 20px;
    }
    
    .hero-left button {
        font-size: 20px;
    }
    .lower-hero{
        padding: 17px 6% 17px 6%;
        background-color: #e7e6e6;
    }
    
    .features h2 {
        font-size: 18px;
    }
    
    .features img {
        max-width: 25px;
        margin-right: 8px;
    }
    .countdown2 {
        font-size: 24px;
    }

    .timer-box2 {
        font-size: 40px;
        padding: 6px 8px;
    }

    .timer-label-inline {
        font-size: 18px;
    }
}

@media(max-width:1024px){
    .hero-left, .hero-right {
        /* On smaller screens, they take full width */
       margin: 6% 6% 6% 0%; /* Updated to add margin on the right for .hero-left */
   }
   .hero-right img {
       max-width: 280px; /* Adjust this value if the image is still too large */
   }
   .hero-left {
       margin: 6% 0 0% 6%;
       padding: 20px;
       
   }
   .hero-left h1{
       font-size: 40px;
       line-height: 45px;
   }
   .hero-left p {
       line-height: 28px;
       font-size: 18px;
   }
   
   .hero-left button {
       font-size: 20px;
   }
   .lower-hero{
       padding: 17px 6% 17px 6%;
       background-color: #e7e6e6;
   }
   
   .features h2 {
       font-size: 15px;
   }
   
   .features img {
       max-width: 20px;
       max-height: 20px;
       margin-right: 8px;
   }
   .countdown2 {
        font-size: 22px;
    }

    .timer-box2 {
        font-size: 36px;
        padding: 5px 7px;
    }

    .timer-label-inline {
        font-size: 16px;
    }
}

@media(max-width:800px){
    .hero-left, .hero-right {
        /* On smaller screens, they take full width */
       margin: 6% 6% 7% 0%; /* Updated to add margin on the right for .hero-left */
   }
   .hero-right img {
       max-width: 260px; /* Adjust this value if the image is still too large */
   }
   .hero-left {
       margin: 6% 0 0% 6%;
       padding: 20px;
       
   }
   .hero-left h1{
       font-size: 30px;
       line-height: 35px;
   }
   .hero-left p {
       line-height: 25px;
       font-size: 15px;
   }
   
   .hero-left button {
       font-size: 15px;
   }
   .lower-hero{
       padding: 17px 6% 17px 6%;
       background-color: #e7e6e6;
   }
   
   .features h2 {
       font-size: 12px;
   }
   
   .features img {
       max-width: 18px;
       max-height: 18px;
       margin-right: 7px;
   }
   .countdown2 {
        font-size: 20px;
    }

    .timer-box2 {
        font-size: 32px;
        padding: 4px 6px;
    }

    .timer-label-inline {
        font-size: 14px;
    }
}


@media(max-width:500px){
    .hero-left, .hero-right {
        /* On smaller screens, they take full width */
       margin: 9% 6% 9% 0%; /* Updated to add margin on the right for .hero-left */
   }
   .hero-right img {
       max-width: 160px; /* Adjust this value if the image is still too large */
       border-radius: 15px;
    }
   .hero-left {
       margin: 6% 0 0% 6%;
       padding: 20px;
       
   }
   .hero-left h1{
       font-size: 20px;
       line-height: 25px;
       
   }
   .hero-left p {
       line-height: 15px;
       font-size: 10px;
   }
   
   .hero-left button {
       font-size: 10px;
       padding: 5px 10px 5px 10px;
       margin-top: 0;
   }
   .lower-hero{
       padding: 12px 6% 12px 6%;
       background-color: #e7e6e6;
   }
   
   .features h2 {
       font-size: 10px;
       
   }
   
   .features img {
       max-width: 15px;
       max-height: 15px;
       margin-right: 5px;
   }
   .countdown2 {
        font-size: 18px;
    }

    .timer-box2 {
        font-size: 28px;
        padding: 3px 5px;
    }

    .timer-label-inline {
        font-size: 12px;
    }
}

@media(max-width: 400px) {
    .hero-left
     {
        /* On smaller screens, they take full width */
        margin: 9% 5% 9% 0%; /* Updated to add margin on the right for .hero-left */
    }

    .hero-right{
        margin: 9% 3% 9% 0%;
    }

    .hero-right img {
        max-width: 160px; /* Adjust this value if the image is still too large */
        border-radius: 15px;
    }

    .hero-left {
        margin: 4% 0 0% 1%;
        padding: 20px;
    }

    .hero-left h1 {
        font-size: 18px;
        line-height: 20px;
    }

    .hero-left p {
        line-height: 13px;
        font-size: 10px;
    }

    .hero-left button {
        font-size: 10px;
        padding: 5px 10px 5px 10px;
        margin-top: 0;
    }

    .lower-hero {
        padding: 12px 6% 0px 6%;
        background-color: #e7e6e6;
    }

    .features h2 {
        font-size: 8px;
        margin-right: 2px;
    }

    .features img {
        max-width: 13px;
        max-height: 13px;
        margin-right: 5px;
    }

    .countdown2 {
        font-size: 10px;
        text-align: center;
    }

    .timer-box2 {
        font-size: 14px;
        padding: 2px 4px;
        margin: 0 2px;
    }

    .timer-label-inline {
        font-size: 8px;
    }
}
