.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    color:black;
    max-width: 500px;
    width: 90%;
    text-align: center;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .modal-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #696969;
    color: white;
    transition: background-color 0.3s ease; 
  }
  
  .modal-buttons button:hover {
    background-color: #505050;
  }
  
  
  .pad{
      padding: 20px;
      
  }
  
  select {
      display: none;
  }
  
  .flex {
      display: flex;
      justify-content: space-between;
      margin:auto;
  }
  
  .flex h3{
      font-size: 25px;
      margin-right: 20px;
      margin-bottom: 15px;
  }
  .marginRight{
      margin: 0 150px 0 0;
  }
  
  
  
  .style label {
      padding: 5px 15px 5px 15px;
      font-size: 20px;
  }
  
  .style input {
      position: absolute;
      opacity: 0;
  }
  
  .style2 {
      margin: 10px 0px 10px 0;
      width: 350px;
  }
  
  .style2 input {
      position: absolute;
      opacity: 0;
  }
  
  .style2 label{
      padding: 5px 15px 5px 15px;
  }
  
  .selected-unit{
      background-color: #696969;
  
  }
  
  .unselected-unit{
      background-color: lightgrey;
  }
  .userInput-style{
      display: flex;
      margin: 10px 0 10px 0;
  }
  
  
  .userInput-style p{
      margin: 0 5px 0 5px;
  }
  .userInput-style input{
      border: none;
      border-radius: 30px;
      -moz-appearance: textfield;
      padding: 8px 5px 8px 10px;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
  }
  
  .userInput-style input:focus{
      outline-width: 0;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .infoDisplay{
      font-weight: 600;
      font-size: 40px;
  }
  
  .font12{
      font-size: 15px;
  }
  
  .cart-button {
      margin-top: 20px;
  }
  
  .cart-button button {
      font-family: 'Poppins';
      background-color: white; /* Green background for the button */
      color: #696969; /* White text color */
      font-size: 16px; /* Sets the size of the font inside the button */
      padding: 10px 24px; /* Top and bottom padding 10px, left and right padding 24px */
      border: none; /* No border for a cleaner look */
      border-top-left-radius: 10px; /* Rounded top left edge */
      border-bottom-left-radius: 10px; /* Rounded bottom left edge */
      border-top-right-radius: 10px; /* Rounded top right edge */
      border-bottom-right-radius: 10px; /* Rounded bottom right edge */
      cursor: pointer; /* Changes the mouse cursor to a pointer when hovered */
      transition: background-color 0.3s ease; /* Smooth transition for the background color on hover */
  }
  
  .cart-button button:hover {
      background-color: #d8d8d8; /* Darker shade of green when the button is hovered over */
  }
  .price-type{
    display: flex;
    justify-content: space-between;
    margin: auto;
  }
  .summary{
    display: flex;
    justify-content: space-between;
    
  }
  
@media (max-width: 500px) {
    .price-type{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: auto;
        row-gap: 10px;
        margin-bottom: 30px;
      }
  }
  @media (max-width: 400px) {
    .summary{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        row-gap: 10px;
      }
  }
  