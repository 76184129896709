.productDisplay {
  display: flex;
  justify-content: space-between;
  margin: 20px 3% 20px 3%;
}
.productDisplay-left {
  display: flex;
  width: 30%;
}
.productDisplay-right {
  display: flex;
  width: 68%;
  justify-content: space-between;
}
.productDisplay-img {
  min-width: 200px;
}
.productDisplay-imgList img {
  max-height: 100px;
  display: flex;
  margin: 0 5px 5px 0;
}

.productDisplay-img img {
  margin: 0;
  padding: 0;
  width: 100%;
}

.productDisplay-img h2 {
  text-align: center;
  background-color: #696969;
  color: white;
  line-height: 25px;
  padding: 10px 0 5px 0;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productDisplay-img h2:hover {
  background-color: #6aa84f;
}

.productDisplay-img h2 span {
  font-size: 15px;
  color: #6aa84f;
  font-weight: 500;
}

.productDisplay-right-left {
  width: 45%;
  box-sizing: border-box;
}

.priceWrapper {
  display: flex;

  padding-bottom: 8px;
  margin-bottom: 5px;
}

.details-header {
  margin-top: 6px;
}

.hr-style {
  width: 60%;
  height: 1px;
  border-radius: 100px;
  background-color: rgb(140, 140, 140);
}

.productDisplay-priceOld {
  margin: 0 0 0 10px;
  color: rgb(157, 157, 157);
}

.productDisplay-priceOld span {
  font-weight: 600;
}

.product-info {
  display: flex;
  margin-bottom: 15px;
}

.product-info-left {
  margin-right: 10px;
}

.product-info-left span {
  font-weight: 500;
}

.product-info-right span {
  font-weight: 500;
}

.productDisplay-desc {
  margin-bottom: 20px;
  text-wrap: balance;
}

.priceCalculator {
  background-color: #6aa84f;
  color: white;
  padding: 10px;
  border-radius: 10px;
}
.productDisplay-right-right {
  width: 50%;
}
/* Media Queries */
@media (max-width: 1280px) {
  .productDisplay {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
  }
  .productDisplay-left {
    display: flex;
    width: 50%;
    justify-content: center;
  }
  .productDisplay-right {
    display: flex;
    width: 100%;
  }
  .productDisplay-imgList img {
    max-height: 100px; /* Slightly smaller images */
  }
  .productDisplay-img img {
    max-width: 500px;
    width: 100%;
  }
}

@media (max-width: 900px) {
  .productDisplay-right {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    row-gap: 20px;
  }
  .productDisplay-left {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .productDisplay-right-left {
    width: 100%;
    padding: 0 20px;
  }
  .productDisplay-right-right {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .productDisplay-img h2 {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
  .productDisplay-imgList img {
    max-height: 80px;
  }
}

@media (max-width: 500px) {
  .productDisplay-imgList {
    justify-content: space-between;
  }
  .productDisplay-img h2 {
    padding: 5px 0; /* Less padding */
  }
  .productDisplay-img h2 span {
    font-size: 13px; /* Smaller font size for the span */
  }
  .infoDisplay {
    font-weight: 600;
    font-size: 30px;
  }
}

@media (max-width: 400px) {
  .productDisplay-left {
    flex-direction: column;
  }
  .productDisplay-imgList {
    display: flex;
    width: fit-content;
    flex-direction: row;
  }
  .productDisplay-imgList img {
    max-width: 100%; /* Full width of container */
    margin-bottom: 10px; /* Increase margin */
  }
  .product-info {
    display: flex;
    margin-bottom: 15px;
    flex-direction: column;
  }
}
