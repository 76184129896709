.reviews-main{
    margin: 20px 6% 20px 6%;
    height: 340px;
}

.reviews-main h2{
    text-align: center;
    margin-bottom: 20px;
    color: rgb(59, 59, 59);
}

.reviews {
    background: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    margin: auto;
  }
  
  .review h4 {
    margin: 0;
    color: #333;
  }
  
  .review p {
    font-size: 14px;
    color: #666;
  }
  
  .rating {
    color: gold;
  }
  
  .star {
    font-size: 20px;
  }
  
  .star.filled {
    color: gold;
  }

  /* Media Queries */

/* Large Tablets and small desktops */
@media (max-width: 1024px) {
  .reviews {
      width: 80%; /* Adjust width to be more flexible instead of fixed */
  }
}

/* Tablets */
@media (max-width: 800px) {
  .reviews {
      width: 90%; /* Increase width percentage for smaller tablets */
  }
  .review p {
      font-size: 13px; /* Slightly smaller font size for better readability */
  }
  .star {
      font-size: 18px; /* Adjust star icon size */
  }
}

/* Large Phones */
@media (max-width: 500px) {
  .reviews-main {
      margin: 10px 3%; /* Adjust outer margin */
      height: auto; /* Allow height to adjust based on content */
  }
  .reviews {
      padding: 15px; /* Adjust padding */
  }
  .review h4, .review p {
      font-size: 12px; /* Smaller font size for content text */
  }
  .star {
      font-size: 16px; /* Smaller stars */
  }
}

/* Small Phones */
@media (max-width: 400px) {
  .reviews-main {
      margin: 10px 2%; /* Further reduce margin for very small screens */
  }
  .reviews {
      padding: 10px; /* Reduce padding to save space */
  }
  .review h4, .review p {
      font-size: 11px; /* Further reduce font size for small phones */
  }
  .star {
      font-size: 15px; /* Even smaller stars */
  }
}