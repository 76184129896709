.popular {

    margin: 0 6% 0 5%;
    padding: 25px 0 30px 0;
}

.popular h1 {
    text-align: center;
    margin-bottom: 20px;
    color: rgb(59, 59, 59);
}



.popular-item {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 20px;
}


@media (max-width: 1024px) {
    .popular-item {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px; /* Adjust gap as needed */
    }
    
}

/* Switch to a two-column grid layout for viewports 930px and below */
@media (max-width: 930px){
    .popular-item {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two items per row */
        gap: 20px; /* Adjust gap as needed */
    }
}

/* Single column layout for viewports narrower than 800px */
@media (max-width: 600px) {
    .popular-item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    /* Further adjust margins and paddings */
    .popular {
        margin: 0 4% 0 4%;
        padding: 20px 0 25px 0;
    }
    .popular h1 {
        font-size: 24px;
    }
}

/* Adjustments for very small screens */
@media (max-width: 500px) {
    .popular-item {
        gap: 20px;
    }
    .popular h1 {
        font-size: 20px;
    }
}

@media (max-width: 400px) {
    /* Adjustments for the smallest screens */
    .popular {
        margin: 0 3% 0 3%;
        padding: 15px 0 20px 0;
    }
    .popular-item img {
        max-width: 90%;
    }
}
