.shop-category {
  display: flex;
  flex-direction: row; /* Default flex direction */
}

.sidebar {
  width: 250px;
  padding: 20px;
  border-right: 1px solid #ccc;
  background-color: #ffffff; /* Sidebar background set to white */
  color: #333; /* Dark text for better contrast on light background */
  display: flex;
  flex-direction: column;
}

.sorting-controls {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}

.sorting-controls select,
.sorting-controls label {
  margin-bottom: 10px; /* Space between form elements */
}

.sort-title {
  font-weight: 600;
}
.search-container {
  display: flex;
  margin-bottom: 20px;
}

.search-input {
  font-family: 'Poppins';
  flex-grow: 1; /* Take up remaining space */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 20px 0 0 20px; /* Rounded corners on the left side */
  background-color: #f8f8f8; /* Light background for input */
}

.shop-select {
  display: block; /* Ensures dropdowns are always displayed */
  width: 100%; /* Full width of container */
  padding: 8px; /* Padding for better touch */
  margin-bottom: 10px; /* Space between each select */
  border: 1px solid #ccc; /* Border styling */
  border-radius: 20px; /* Rounded corners */
  background-color: #fff; /* Background color */
  -webkit-appearance: none;  /* Remove default Chrome & Safari style */
  -moz-appearance: none;     /* Remove default Firefox style */
  appearance: none;          /* Remove default style (catch-all) */
  background-image: url('../../Components/Assets/chevron-down-solid.svg'); /* Path to your custom arrow icon */
  background-repeat: no-repeat;
  background-position: right 12px center; /* Adjust this value to move the arrow */
  background-size: 15px auto; /* Max width for the SVG, maintaining aspect ratio */
  padding-right: 30px; /* Ensure text does not overlap the arrow icon */
}

.shop-select:focus {
  outline: none; /* Removes focus outline for cleaner look */
  border-color: #5DCB53; /* Highlight color when selected */
}

.clear-button {
  font-family: 'Poppins';
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-left: none; /* Aligns seamlessly with the input field */
  background-color: #5DCB53; /* Button color to match nav bar buttons */
  cursor: pointer;
  border-radius: 0 20px 20px 0; /* Rounded corners on the right side */
  color: #ffffff; /* White text on buttons */
}

.clear-button:hover {
  background-color: #408d39; /* Darker green on hover */
}

.product-area {
  flex-grow: 1;
  margin-left: 20px;
}

.product-count {
  margin: 15px 0 20px; /* Adds space between the product count and the product grid */
  color: #ababab;
  font-weight: 600;
}

.checkbox-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 3 columns */
}

.checkbox-container input {
  margin-right: 5px;
}

.shopcategory-products {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px; /* Space between products */
  margin-bottom: 20px;
}

/* CSS for the toggle button */
.toggle-button {
  display: none; /* Hide by default */
  font-family: 'Poppins';
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: #5DCB53;
  cursor: pointer;
  border-radius: 20px;
  color: #ffffff;
  margin-bottom: 10px;
}

.toggle-button:hover {
  background-color: #408d39;
}

/* CSS for the sorting controls visibility */
.sorting-controls.show {
  display: block !important;
}

@media (max-width: 1024px) {
  .sidebar,
  .product-area {
    padding: 10px;
  }
  .shopcategory-products {
    grid-template-columns: repeat(2, 1fr); /* Three columns on medium screens */
  }
}

@media (max-width: 800px) {
  .toggle-button {
    display: block; /* Show the toggle button on small screens */
  }

  .sidebar .sorting-controls {
    display: none; /* Hide the sorting controls by default on small screens */
  }

  .sidebar .sorting-controls.show {
    display: block; /* Show the sorting controls when the state is true */
  }

  .shop-category {
    flex-direction: column; /* Stacks sidebar on top */
  }
  .sidebar {
    width: 95%; /* Full width for mobile */
    border-right: none;
    border-bottom: 1px solid #ccc; /* Add bottom border instead of right border */
  }
  .product-area {
    margin-left: 0;
    margin-top: 20px; /* Add margin to top for spacing */
  }
  .shopcategory-products {
    grid-template-columns: repeat(2, 1fr); /* Two columns on smaller screens */
  }
}

@media (max-width: 500px) {
  .shopcategory-products {
    grid-template-columns: 1fr; /* Single column on very small screens */
  }
}

@media (max-width: 400px) {
  .sidebar,
  .product-area {
    margin: 10px 4% 0 4%; /* Adjust margins for smaller screens */
  }

  .sidebar{
    width: 90%;
  }
}

.load-more-button {
  font-family: 'Poppins';
  padding: 10px 20px;
  border: 1px solid #ccc;
  background-color: #5DCB53;
  cursor: pointer;
  border-radius: 20px;
  color: #ffffff;
  display: block;
  margin: 20px auto;
}

.load-more-button:hover {
  background-color: #408d39;
}

.price-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.price-input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
}

.price-input1 {
  width: 85%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 20px 0px 0px 20px;
  background-color: #f8f8f8;
}

.price-input2 {
  width: 85%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 0px 20px 20px 0px;
  background-color: #f8f8f8;
}

.price-input1:focus {
  outline: none;
  border-color: #5DCB53;
}

.price-input2:focus {
  outline: none;
  border-color: #5DCB53;
}
