.About {
    font-family: 'Poppins', sans-serif; /* Consistent, readable font */
    color: white; /* Softer gray text for readability */
    padding: 20px;
    max-width: 1200px; /* Max width for optimal reading */
    margin: auto; /* Centering the content */
    background-color: #fff; /* Bright white background */
}

.about-top{
    background-color: #696969;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 30px;
}

.about-bottom{
    background-color: #696969;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 30px;
}

.title{
    margin: 20px 0;
    text-align: center;
}

.green-title {
    color: #6AA84F; /* Green color for headings */
}

.h1-style {
    margin-top: 0;
    font-size: 2.5em; /* Large font size for main title */
}

.h2-style {
    font-size: 2em;
    border-bottom: 2px solid #6AA84F; /* Green underline effect for secondary headings */
    padding-bottom: 10px;
}

.h3-style {
    font-size: 1.5em;
    margin-top: 20px;
}

.p-style {
    font-size: 1em;
    line-height: 1.6; /* Spacing for readability */
    margin-top: 10px;
    text-align: justify; /* Justified text for a cleaner look */
}

ul {
    list-style-type: none; /* No bullets for a cleaner look */
    padding: 0;
}

li {
    margin-bottom: 10px; /* Space between list items */
    font-size: 1em;
    line-height: 1.6;
}

li strong {
    color: #6AA84F; /* Green for highlighted keywords */
}

/* Link styling within the component */
a {
    color: #6AA84F; /* Green color for links */
    text-decoration: none; /* No underline to keep it clean */
}


/* Responsive design breakpoints */
@media (max-width: 768px) {
    .About {
        padding: 10px;
    }

    .h1-style {
        font-size: 2em; /* Smaller font size for small devices */
    }

    .h2-style {
        font-size: 1.75em;
    }

    .h3-style {
        font-size: 1.25em;
    }

    .p-style, li {
        font-size: 0.9em; /* Smaller text on small devices */
    }
}