.breadcrumb {
    margin: 10px 0 0 6%;
    color: rgb(157, 157, 157);
}

.breadcrumb img {
    max-height: 12px;
}

.breadcrumb span {
    font-weight: 600;
    margin-left: 2.5px;
}

.breadcrumb-link {
    color: rgb(157, 157, 157);
    text-decoration: none;
    margin: 0px 5px;
}