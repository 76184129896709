.new-sales {
    margin: 0;
    background-color: #f8f9fa; /* Light grey background */
}

.sale-header {
    background-image: url('../Assets/hero_dark.jpg'); /* Using showroom image as background */
    background-size: cover; /* Cover the entire area of the div */
    background-position: center; /* Center the background image */
    color: white; /* Ensure text color is white for better readability */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 15%; /* Adjust padding to ensure content is well-spaced */
}

.sale-image {
    width: 20%; /* Adjust size as necessary */
    /* Optional: make the image rounded */
}

.sale-info {
    text-align: center;
    
    background-color: #6AA84F;
    border-radius: 20px;
    padding: 10px 20px 20px 20px;
}

.sale-info h1 {
    margin-bottom: 0px;
    font-size: 50px;
}

.sale-tagline {
    font-size: 16px;
    margin-bottom: 10px;
}

.countdown {
    display: flex;
    font-size: 24px;
    font-weight: bold;
    align-items: center;
}

.timer-box {
    background-color: white; /* Keeping timer boxes white for contrast */
    color: black; /* Text color for readability */
    padding: 8px 10px;
    border-radius: 5px; /* Rounded corners for aesthetic */
    margin: 0 5px;
    
}

.new-item {
    display: grid;
    justify-content: space-around;
    margin: 2% 6% 0% 6%;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

@media (max-width: 1024px) {
    .new-item {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px; /* Adjust gap as needed */
    }
    .sale-header {
        background-image: url('../Assets/hero_dark.jpg'); /* Using showroom image as background */
        background-size: cover; /* Cover the entire area of the div */
        background-position: center; /* Center the background image */
        color: white; /* Ensure text color is white for better readability */
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 30px 15%; /* Adjust padding to ensure content is well-spaced */
    }
    .sale-info{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .sale-info h1 {
        margin-bottom: 0px;
        font-size: 40px;
    }
}

/* Switch to a two-column grid layout for viewports 930px and below */
@media (max-width: 930px){
    .new-item {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two items per row */
        gap: 20px; /* Adjust gap as needed */
    }
}
@media (max-width: 600px) {
    .new-item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 400px) {
    .sale-info h1 {
        margin-bottom: 0px;
        font-size: 30px;
    }
}
