.search-bar-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%; /* Make the container take full width */
}

.search-bar-input {
    padding: 8px 12px;
    border: none;
    border-radius: 20px;
    width: 100%; /* Make the input take full width */
    max-width: 250px; /* Maximum width */
    font-size: 16px;
    color: #333;
    background-color: #fff;
    outline: none;
    transition: all 0.3s ease-in-out;
}

.search-bar-input::placeholder {
    color: #888;
}

.search-bar-input:focus {
    box-shadow: 0 0 8px rgba(93, 203, 83, 0.5);
    background-color: #f9f9f9;
    border-radius: 20px 20px 0 0;
}

.search-results-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: white;
    box-shadow: 0 8px 16px rgba(0,0,0,0.15);
    border-radius: 0 0 8px 8px;
    display: flex;
    flex-direction: column;
    z-index: 1000; /* Ensure the results are above other elements */
}

.search-result-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.search-bar-container input:focus + .search-results-container {
    display: block;
}

.search-result-item {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    font-size: 16px;
    color: #333;
    text-decoration: none;
}

.search-result-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.search-result-item:hover {
    background-color: #f0f0f0;
}

@media (max-width: 1024px) {
    .search-bar-input {
        max-width: 200px;
    }
}

@media (max-width: 800px) {
    .search-bar-container {
        display: flex;
        justify-content: center;
        margin-right: 10px;
        align-items: center;
    }
    .search-bar-input {
        max-width: 80%;
        padding: 6px 10px;
        font-size: 14px;
        align-items: center;
    }
}

@media (max-width: 500px) {
    .search-bar-container {
        flex-grow: 0;
        align-items: center;
        justify-content: center;
    }
    .search-bar-input {
        max-width: 80%;
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    .search-bar-container {
        flex-grow: 0;
        margin-right: 5px;
    }
    .search-bar-input {
        max-width: 80%;
        font-size: 12px;
    }
    .search-result-item {
        font-size: 14px;
        padding: 8px 10px;
    }
    .search-result-img {
        width: 25px;
        height: 25px;
    }
}

