.item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.item a{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item img{
    width: 100%;
}
.item p{
    width: 100%;
    text-align: center;
}
.items-prices {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.item-price-new {
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}

.item-price-old {
    color: #9b9b9b;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}

.item:hover {
    transform: scale(1.02);
    transition: 0.6;
}