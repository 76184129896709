.loginSignup{
    width: 100%;
    padding-top: 80px;
    margin-bottom: 80px;
}

.loginSignup-container {
    width: 580px;
    height: auto; /* Changed to auto to accommodate variable content size */
    background-color: #696969;
    margin: auto;
    padding: 40px 60px;
}

.loginSignup-container h1 {
    margin: 20px 0px;
    color: white;
}

.loginSignup-fields {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
}

.loginSignup-fields input {
    height: 72px;
    width: 100%;
    padding-left: 20px;
    border: 1px solid #c9c9c9;
    outline: none;
    color: #5c5c5c;
    font-size: 15px;
}

.loginSignup-container button {
    width: 100%; /* Adjusted to be responsive */
    height: 72px;
    color: white;
    background: #6AA84F;
    margin-top: 30px;
    border: none;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.loginSignup-login {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    color: white;
}

.loginSignup-login span{
    cursor: pointer;
    font-weight: 600;
    color: #6AA84F;
}

.loginSignup-agree {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 10px;
    font-size: 18px;
    font-weight: 500;
    color: white;
}

.error{
    color: red;
}

/* Media Queries */
@media (max-width: 1280px) {
    .loginSignup-container {
        width: 500px; /* Slightly smaller container */
    }
}

@media (max-width: 1024px) {
    .loginSignup-container {
        width: 450px;
        padding: 30px 50px; /* Adjust padding for smaller screens */
    }
}

@media (max-width: 800px) {
    .loginSignup-container {
        width: 80%; /* More flexible width for very small devices */
        padding: 20px 30px;
    }
    .loginSignup-container h1, .loginSignup-fields input, .loginSignup-login, .loginSignup-agree {
        font-size: 16px; /* Smaller font size for all text elements */
    }
}

@media (max-width: 500px) {
    .loginSignup-container {
        width: 90%; /* Maximize use of available space */
        padding: 15px 20px;
    }
    .loginSignup-container h1, .loginSignup-fields input, .loginSignup-login, .loginSignup-agree {
        font-size: 14px; /* Further reduce font size for very small screens */
    }
    .loginSignup-fields input, .loginSignup-container button {
        height: 50px; /* Reduce input and button height for small screens */
    }
}

