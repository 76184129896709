.gallery {
    margin: 20px 6%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 15px;
  }
  
  .photo-item {
    position: relative;
    height: 400px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Add transition for transform */
  }
  
  .gallery-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out; /* Transition for a subtle fade effect */
  }
  
  .photo-item:hover {
    transform: scale(1.05); /* Scale the container instead of the image */
    box-shadow: 0 6px 12px rgba(0,0,0,0.2);
  }
  
  .main-container h2 {
    text-align: center;
    margin: 30px 0 10px 0;
  }
  
  .filter-buttons {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .filter-buttons button {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    border: none;
    border-radius: 20px;
    background-color: #6AA84F;
    transition: background-color 0.3s ease-in-out;
  }
  
  .filter-buttons button:hover {
    background-color: #408d39;
  }
  
  .filter-buttons button:focus {
    outline: none;
  }

  @media (max-width:500px){
    .gallery {
      margin: 20px 6%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .photo-item {
      position: relative;
      width: 100%;
      height: fit-content;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  }