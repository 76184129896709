.success-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.success-container h1 {
  color: #5dcb53;
}

.success-container p {
  font-size: 1.1em;
  margin: 10px 0;
}

.order-details {
  text-align: left;
  margin-top: 20px;
}

.order-details h2 {
  color: #333;
}

.order-details ul {
  list-style: none;
  padding: 0;
}

.order-details li {
  margin: 5px 0;
}

/* Media query for screens up to 800px wide */
@media (max-width: 800px) {
  .success-container {
    padding: 15px;
  }

  .success-container p {
    font-size: 1em;
  }

  .order-details h2 {
    font-size: 1.2em;
  }

  .order-details li {
    font-size: 1em;
  }
}

/* Media query for screens up to 600px wide */
@media (max-width: 600px) {
  .success-container {
    padding: 10px;
  }

  .success-container h1 {
    font-size: 1.5em;
  }

  .success-container p {
    font-size: 0.95em;
  }

  .order-details h2 {
    font-size: 1.1em;
  }

  .order-details li {
    font-size: 0.95em;
  }
}

/* Media query for screens up to 400px wide */
@media (max-width: 400px) {
  .success-container {
    margin: 20px auto;
    padding: 10px;
  }

  .success-container h1 {
    font-size: 1.2em;
  }

  .success-container p {
    font-size: 0.9em;
  }

  .order-details h2 {
    font-size: 1em;
  }

  .order-details li {
    font-size: 0.9em;
  }
}

