.policies {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    width: 85%; /* No longer pushes boundaries with box-sizing: border-box */
    margin: 30px 6%;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .policy-section {
    margin-bottom: 10px;
  }
  
  .policy-title {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensures space between text and arrow icon */
    background: #5DCB53;
    color: white;
    padding: 10px 15px;
    border: none;
    width: 100%;
    text-align: left;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .policy-title:hover {
    background: #4fab46;
  }
  
  .policy-content {
    background: white;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 5px;
    border-radius: 4px;
  }
  
  .arrow-icon {
    transition: transform 0.3s ease;
    width: 16px; /* fixed width for better control */
    height: 16px; /* fixed height to match width */
  }
  
  .arrow-icon.open {
    transform: rotate(180deg); /* Rotates the arrow when the section is open */
  }