.seen-in {
    text-align: center;
    padding: 20px;
  }
  
  .seen-in h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .logos-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 80px;
  }
  
  .logos-container img {
    max-width: 200px;
    height: auto;
  }
  
  /* Media queries for different screen sizes */
  @media (max-width: 1024px) {
    .logos-container img {
      width: 25%;
    }
  }
  
  @media (max-width: 800px) {
    .logos-container img {
      width: 33.3333%;
    }
  }
  
  @media (max-width: 500px) {
    .logos-container img {
      width: 50%;
    }
  }
  
  @media (max-width: 400px) {
    .logos-container img {
      width: 100%;
    }
  }