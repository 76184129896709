.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 80vh;
    padding: 20px;
  }
  
  .not-found-container img {
    max-width: 90%;
    max-height: 400px;
  }
  
  .not-found-container h1 {
    margin: 20px 0 10px;
  }
  
  .not-found-container p {
    margin-bottom: 20px;
  }
  
  .not-found-container button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: rgb(49, 178, 62);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .not-found-container button:hover {
    background-color: rgb(38, 138, 48);
  }